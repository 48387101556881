import type { paths } from "resources/api-schema";
import {
  ExtractRequestBody,
  ExtractSuccessType,
  ParametersProperty,
} from "core/entities/APITypes";
import { useCallback } from "react";
import { validateResponse } from "core/utils/validateResponse";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useRouter } from "next/router";
import { useAPIHeaders } from "./useAPIHeaders";
import { buildCentrixUrl } from "../utils/buildCentrixUrl";
import { useTrackException } from "./useTrackException";

export type ApiPostArgs<
  T extends keyof paths,
  C extends "application/json" | "multipart/form-data" = "application/json",
> = {
  path: T;
  body: ExtractRequestBody<T, "post", C>;
} & ParametersProperty<T, "post">;

export async function postJsonToCentrix<T extends keyof paths>(
  { path, parameters, body, locale }: ApiPostArgs<T> & { locale?: string },
  options?: RequestInit
): Promise<ExtractSuccessType<T, "post">> {
  const url = buildCentrixUrl({ path, parameters, locale });
  const res = await fetch(url, {
    ...options,
    method: "POST",
    headers: {
      ...options?.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  await validateResponse(res);
  try {
    const data = await res.json();
    return data as ExtractSuccessType<T, "post">;
  } catch (error: any) {
    error.info = { message: "Error parsing JSON" };
    error.status = res.status;
    throw error;
  }
}

export function usePostJsonToCentrix() {
  const headers = useAPIHeaders();
  const { locale } = useRouter();
  return useCallback(
    <T extends keyof paths>(postArg: ApiPostArgs<T>) =>
      postJsonToCentrix({ ...postArg, locale }, { headers }),
    [headers, locale]
  );
}

export function useApiPostJsonWithTracking() {
  const trackException = useTrackException();
  const post = usePostJsonToCentrix();
  return useCallback(
    <T extends keyof paths>(
      postArgs: ApiPostArgs<T>,
      trackExceptionProperties?: Record<string, any>
    ) =>
      post(postArgs).catch((error) => {
        trackException({
          exception: error,
          severityLevel: SeverityLevel.Error,
          properties: {
            ...trackExceptionProperties,
            functionName: "useApiPostJsonWithTracking",
            args: postArgs,
          },
        });
        throw error;
      }),
    [post, trackException]
  );
}
