import type { paths } from "resources/api-schema";
import {
  ExtractRequestBody,
  ExtractSuccessType,
  ParametersProperty,
} from "core/entities/APITypes";
import { useCallback } from "react";
import { validateResponse } from "core/utils/validateResponse";
import { useRouter } from "next/dist/client/router";
import { useAPIHeaders } from "./useAPIHeaders";
import { buildCentrixUrl } from "../utils/buildCentrixUrl";

export type ApiPutJsonArgs<T extends keyof paths> = {
  path: T;
  body: ExtractRequestBody<T, "put">;
} & ParametersProperty<T, "put">;

export function usePutJsonToCentrix() {
  const headers = useAPIHeaders();
  const { locale } = useRouter();
  return useCallback(
    async <T extends keyof paths>({
      path,
      parameters,
      body,
    }: ApiPutJsonArgs<T>): Promise<ExtractSuccessType<T, "put">> => {
      const url = buildCentrixUrl({ path, parameters, locale });
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          ...headers,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      await validateResponse(res);
      const jsonData = await res.json();
      return jsonData;
    },
    [headers, locale]
  );
}
